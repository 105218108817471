@import '~bpk-mixins';

.bpk-text {
  @include bpk-text;

  &--xs {
    @include bpk-text--xs;
  }

  &--sm {
    @include bpk-text--sm;
  }

  &--base {
    @include bpk-text--base;
  }

  &--lg {
    @include bpk-text--lg;
  }

  &--xl {
    @include bpk-text--xl;
  }

  &--xxl {
    @include bpk-text--xxl;
  }

  &--xxxl {
    @include bpk-text--xxxl;
  }

  &--xxxxl {
    @include bpk-text--xxxxl;
  }

  &--xxxxxl {
    @include bpk-text--xxxxxl;
  }

  &--bold {
    @include bpk-text--bold;
  }

  &--black {
    @include bpk-text--black;
  }
}
